export const PaymentEnums = {
  CREDITCARD: "creditcard",
  ONLINEEFTPOS: "onlineeftpos",
  DIRECTDEBIT: "directdebit",
  COMPLIMENTARY: "complimentary",
  AFTERPAY: "afterpay",
} as const;

export const ImageFormat = {
  PORTRAIT: "Portait",
  PORTRAITLARGE: "Portrait Large",
  PORTRAITSMALL: "Portrait Small",
  BANNER: "Banner (new)",
  SQUARE: "Square",
  NEWSLETTER: "Newsletter",
} as const;

export const EpassRegoInputType = {
  BLANK: "blank",
  TEXT: "text",
  TEXTAREA: "textarea",
  RADIO: "radio",
  CHECKBOX: "checkbox",
  DATE: "date",
  DATETIME: "datetime",
  DROPDOWN: "dropdown",
} as const;

export const RegoInputType = {
  BLANK: "blank",
  TEXT: "text",
  TEXTAREA: "textarea",
  RADIOBUTTONGROUP: "radiobuttongroup",
  CHECKBOX: "checkbox",
  DATE: "date",
  DATETIME: "datetime",
  DROPDOWN: "dropdown",
} as const;

export const EventMode = {
  LIST: 1,
  CALENDAR: 1,
} as const;

export const ShowingStatus = {
  AVAILABLE: 1,
  SOLDOUT: 2,
  CANCELLED: 3,
  POSTPONED: 4,
  NOTYETONSALE: 5,
  ADMINSOLDOUT: 6,
  FREEENTRY: 7,
} as const;

export const ShowingGroupMode = {
  GROUPED: 1,
  UNGROUPED: 2,
} as const;

export const AccessType = {
  PUBLIC: "public",
  NOTYETONSALE: "notyetonsale",
  NOTONSALE: "notonsale",
  DOORSALES: "doorsales",
  SALESCLOSED: "salesclosed",
  PASSWORD: "password",
  EXPIRED: "expired",
} as const;

export const PriceStatus = {
  NOTAVAILABLE: 0,
  AVAILABLE: 1,
  SOLDOUT: 2,
  BOOKEDOUT: 3,
  LIMITREACHED: 4,
} as const;

export const OrderStatus = {
  AWAITING: "Awaiting Payment",
  COMPLETE: "Complete",
} as const;

export const PaymarkEvents = {
  SUBMITTED: "payment_submitted",
  CHALLENGED: "payment_challenged",
  ID_UPDATED: "payment_id_updated",
  SUBMISSION_RESPONSE: "payment_submission_response",
  CHALLENGED_RESPONSE: "payment_challenge_response",
  INPUT: "payment_input",
  EXPIRED: "payment_expired",
  INVALID: "payment_id_invalid",
} as const;

export const TicketShareStatus = {
  PENDING: 1,
  SUCCESS: 2,
  DECLINED: 3,
  WITHDRAWN: 4,
  EXPIRED: 5,
} as const;

export const TicketStatus = {
  CANCELLED: 10,
} as const;

export const AnalyticsTypeEnum = {
  GA4: "ga4",
  METAPIXEL: "metapixel",
} as const;
