import { joinClassNames } from "@utils/helpers";
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from "react";

interface Props extends ComponentPropsWithoutRef<"input"> {
  /**
   * Is input value the desired format? Example, you may pass a logic to ensure the input
   *  value is a valid email address
   */
  invalidInput?: boolean;
}

/**
 * A custom styled input that is resuable throughout the application
 */
const StyledInput = forwardRef(
  (
    { invalidInput, className, ...props }: Props,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <input
        type="text"
        ref={ref}
        name={props.id}
        className={joinClassNames(
          className ?? "",
          "flex-1 block py-1.5 px-2 w-full h-9 border text-black border-gray-300 selection:bg-gray-500 disabled:text-gray-600 disabled:bg-gray-200 rounded shadow-sm sm:text-sm focus:ring-1 focus:border-indigo-500 focus-visible:outline-none",
          invalidInput
            ? "ring-2 ring-red-500 focus:ring-red-500"
            : " focus:ring-indigo-500"
        )}
        {...props}
      />
    );
  }
);

StyledInput.displayName = "StyledInput";

export default StyledInput;
