import { joinClassNames } from "@utils/helpers";
import { HTMLAttributes } from "react";

/**
 * Primary UI wrapper component for skeleton loading. This is primarily used as visual placeholders for information while data is still loading
 *
 */
const Skeleton = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={joinClassNames(className ?? "", "animate-pulse bg-gray-100")}
      {...props}
    />
  );
};

export default Skeleton;
